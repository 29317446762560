/*
 * @Author: wqy wuqyhf@163.com
 * @Date: 2022-05-06 09:25:02
 * @LastEditors: wqy wuqyhf@163.com
 * @LastEditTime: 2022-05-07 10:50:20
 * @FilePath: \4.珠海格力\code\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createStore } from 'vuex'
import mutations from './mutations';
import getters from './getters';
import actions from './actions';

const state={
    user:{
      isLogin:true,
    }
}
export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
  }
})
