/*
 * @Author: 吴庆垚 wuqyhf@163.com
 * @Date: 2022-05-24 14:39:02
 * @LastEditors: 吴庆垚 wuqyhf@163.com
 * @LastEditTime: 2022-05-27 12:02:44
 * @FilePath: \VueTestSoftware\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import * as Icons from "@ant-design/icons-vue";
import "@/assets/mock/index";
import "./basic/base.css";
import "./basic/common.css";

var app = createApp(App);

app.use(store).use(router).use(Antd).mount("#app");

// 循环使用全部全部图标
const icons = Icons;
for (const i in icons) {
    // 全局注册一下组件
    app.component(i, icons[i]);
}
