/*
 * @Author: 吴庆垚 wuqyhf@163.com
 * @Date: 2022-05-27 11:40:46
 * @LastEditors: 吴庆垚 wuqyhf@163.com
 * @LastEditTime: 2022-05-29 09:02:43
 * @FilePath: \VueTestSoftware\src\mock\login.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Mock from "mockjs"

const login = (data) => {
    let logindata = JSON.parse(data.body);
    if (logindata.username == "test" && logindata.password == "test") {
        return {
            "data": "OK",
            "message": "登录成功！"
        };
    }
    else {
        return {
            "data": "Failed",
            "message": "登录失败，用户名或密码错误！"
        };
    }
};

const getVersionReleaseAndLan = () => {
    let rtnOBj = Mock.mock({
        "object|3": {
            "version": "V22.5",
            "release": "2022.05.29",
            "lan": "zhCN"
        }
    });

    return rtnOBj.object;
}

export default { login, getVersionReleaseAndLan };
