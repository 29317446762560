import Mock from "mockjs";

const caseData = [
    {
        key: 1,
        img: "images/case/case1.jpg",
        name: "成功案例1",
        text: "冷却塔是利用空气同水的接触(直接或间接)来冷却水的设备。是以水为循环冷却剂，从一个系统中吸收热量并排放至大气中，从而降低塔内温度，制造冷却水可循环使用的设备。",
    },
    {
        key: 2,
        img: "images/case/case2.jpg",
        name: "成功案例2",
        text: "冷却塔是利用空气同水的接触(直接或间接)来冷却水的设备。是以水为循环冷却剂，从一个系统中吸收热量并排放至大气中，从而降低塔内温度，制造冷却水可循环使用的设备。",
    },
    {
        key: 3,
        img: "images/case/case3.jpg",
        name: "成功案例3",
        text: "冷却塔是利用空气同水的接触(直接或间接)来冷却水的设备。是以水为循环冷却剂，从一个系统中吸收热量并排放至大气中，从而降低塔内温度，制造冷却水可循环使用的设备。",
    },
    {
        key: 4,
        img: "images/case/case4.jpg",
        name: "成功案例4",
        text: "冷却塔是利用空气同水的接触(直接或间接)来冷却水的设备。是以水为循环冷却剂，从一个系统中吸收热量并排放至大气中，从而降低塔内温度，制造冷却水可循环使用的设备。",
    },
    {
        key: 5,
        img: "images/case/case5.jpg",
        name: "成功案例5",
        text: "冷却塔是利用空气同水的接触(直接或间接)来冷却水的设备。是以水为循环冷却剂，从一个系统中吸收热量并排放至大气中，从而降低塔内温度，制造冷却水可循环使用的设备。",
    },
    {
        key: 6,
        img: "images/case/case6.jpg",
        name: "成功案例6",
        text: "冷却塔是利用空气同水的接触(直接或间接)来冷却水的设备。是以水为循环冷却剂，从一个系统中吸收热量并排放至大气中，从而降低塔内温度，制造冷却水可循环使用的设备。",
    },
    {
        key: 7,
        img: "images/case/case7.jpg",
        name: "成功案例7",
        text: "冷却塔是利用空气同水的接触(直接或间接)来冷却水的设备。是以水为循环冷却剂，从一个系统中吸收热量并排放至大气中，从而降低塔内温度，制造冷却水可循环使用的设备。",
    },
    {
        key: 8,
        img: "images/case/case8.jpg",
        name: "成功案例8",
        text: "冷却塔是利用空气同水的接触(直接或间接)来冷却水的设备。是以水为循环冷却剂，从一个系统中吸收热量并排放至大气中，从而降低塔内温度，制造冷却水可循环使用的设备。",
    },
    {
        key: 9,
        img: "images/case/case9.jpg",
        name: "成功案例9",
        text: "冷却塔是利用空气同水的接触(直接或间接)来冷却水的设备。是以水为循环冷却剂，从一个系统中吸收热量并排放至大气中，从而降低塔内温度，制造冷却水可循环使用的设备。",
    },
    {
        key: 10,
        img: "images/case/case10.jpg",
        name: "成功案例10",
        text: "冷却塔是利用空气同水的接触(直接或间接)来冷却水的设备。是以水为循环冷却剂，从一个系统中吸收热量并排放至大气中，从而降低塔内温度，制造冷却水可循环使用的设备。",
    },
];

export default {
    caseData,
};
