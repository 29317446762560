import Mock from "mockjs";

const honorData = [
    {
        key: 1,
        img: "images/honor/honor1.jpg",
        name: "荣誉资质1",
        text: "冷却塔是利用空气同水的接触(直接或间接)来冷却水的设备。是以水为循环冷却剂，从一个系统中吸收热量并排放至大气中，从而降低塔内温度，制造冷却水可循环使用的设备。",
    },
    {
        key: 2,
        img: "images/honor/honor2.jpg",
        name: "荣誉资质2",
        text: "冷却塔是利用空气同水的接触(直接或间接)来冷却水的设备。是以水为循环冷却剂，从一个系统中吸收热量并排放至大气中，从而降低塔内温度，制造冷却水可循环使用的设备。",
    },
    {
        key: 3,
        img: "images/honor/honor3.jpg",
        name: "荣誉资质3",
        text: "冷却塔是利用空气同水的接触(直接或间接)来冷却水的设备。是以水为循环冷却剂，从一个系统中吸收热量并排放至大气中，从而降低塔内温度，制造冷却水可循环使用的设备。",
    },
    {
        key: 4,
        img: "images/honor/honor4.jpg",
        name: "荣誉资质4",
        text: "冷却塔是利用空气同水的接触(直接或间接)来冷却水的设备。是以水为循环冷却剂，从一个系统中吸收热量并排放至大气中，从而降低塔内温度，制造冷却水可循环使用的设备。",
    },
    {
        key: 5,
        img: "images/honor/honor5.jpg",
        name: "荣誉资质5",
        text: "冷却塔是利用空气同水的接触(直接或间接)来冷却水的设备。是以水为循环冷却剂，从一个系统中吸收热量并排放至大气中，从而降低塔内温度，制造冷却水可循环使用的设备。",
    },
    {
        key: 6,
        img: "images/honor/honor6.jpg",
        name: "荣誉资质6",
        text: "冷却塔是利用空气同水的接触(直接或间接)来冷却水的设备。是以水为循环冷却剂，从一个系统中吸收热量并排放至大气中，从而降低塔内温度，制造冷却水可循环使用的设备。",
    },
    {
        key: 7,
        img: "images/honor/honor7.jpg",
        name: "荣誉资质7",
        text: "冷却塔是利用空气同水的接触(直接或间接)来冷却水的设备。是以水为循环冷却剂，从一个系统中吸收热量并排放至大气中，从而降低塔内温度，制造冷却水可循环使用的设备。",
    },
    {
        key: 8,
        img: "images/honor/honor8.jpg",
        name: "荣誉资质8",
        text: "冷却塔是利用空气同水的接触(直接或间接)来冷却水的设备。是以水为循环冷却剂，从一个系统中吸收热量并排放至大气中，从而降低塔内温度，制造冷却水可循环使用的设备。",
    },
    {
        key: 9,
        img: "images/honor/honor9.jpg",
        name: "荣誉资质9",
        text: "冷却塔是利用空气同水的接触(直接或间接)来冷却水的设备。是以水为循环冷却剂，从一个系统中吸收热量并排放至大气中，从而降低塔内温度，制造冷却水可循环使用的设备。",
    },
    {
        key: 10,
        img: "images/honor/honor10.jpg",
        name: "荣誉资质10",
        text: "冷却塔是利用空气同水的接触(直接或间接)来冷却水的设备。是以水为循环冷却剂，从一个系统中吸收热量并排放至大气中，从而降低塔内温度，制造冷却水可循环使用的设备。",
    },
];

export default {
    honorData,
};
