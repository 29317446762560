/*
 * @Author: wqy wuqyhf@163.com
 * @Date: 2022-05-07 10:23:21
 * @LastEditors: wqy wuqyhf@163.com
 * @LastEditTime: 2022-05-07 10:31:33
 * @FilePath: \4.珠海格力\code\src\store\mutations.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const mutations={
    setIsLogin(state,para)
    {
        state.user.isLogin=para;
    }
}

export default mutations;