import { createRouter, createWebHashHistory } from "vue-router";

const Index = () =>
    import(/* webpackChunkName: "about" */ "../views/Index.vue");
const About = () =>
    import(/* webpackChunkName: "about" */ "../views/About.vue");
const Product = () =>
    import(/* webpackChunkName: "about" */ "../views/Product.vue");
const Case = () => import(/* webpackChunkName: "about" */ "../views/Case.vue");
const Honor = () =>
    import(/* webpackChunkName: "about" */ "../views/Honor.vue");
const Contact = () =>
    import(/* webpackChunkName: "about" */ "../views/Contact.vue");

const routes = [
    {
        path: "/",
        name: "defaultIndex",
        component: Index,
        meta: {
            title: "合肥立梦-网站首页",
            isAuthorised: false,
        },
    },
    {
        path: "/index",
        name: "index",
        component: Index,
        meta: {
            title: "合肥立梦-网站首页",
            isAuthorised: false,
        },
    },
    {
        path: "/about",
        name: "about",
        component: About,
        meta: {
            title: "合肥立梦-公司简介",
            isAuthorised: false,
        },
    },
    {
        path: "/product",
        name: "product",
        component: Product,
        meta: {
            title: "合肥立梦-产品中心",
            isAuthorised: false,
        },
    },
    {
        path: "/case",
        name: "case",
        component: Case,
        meta: {
            title: "合肥立梦-成功案例",
            isAuthorised: false,
        },
    },
    {
        path: "/honor",
        name: "honor",
        component: Honor,
        meta: {
            title: "合肥立梦-荣誉资质",
            isAuthorised: false,
        },
    },
    {
        path: "/contact",
        name: "contact",
        component: Contact,
        meta: {
            title: "合肥立梦-联系我们",
            isAuthorised: false,
        },
    },
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
});

// 保证页面跳转时在顶部
router.beforeEach((to, from, next) => {
    next();
    window.scrollTo(0, 0);
});

router.beforeEach((to, from, next) => {
    //在这里可以添加前置导航守卫
    if (to.meta.isAuthorised && store.state.user.isLogin) {
        console.log("没有登录，请先登录");
    } else {
        next();
    }

    document.title = to.meta.title;
});

export default router;
