import Mock from "mockjs";

const productData = {
    sys: {
        name: "产品检测系统",
        key: "sys",
        children: [
            {
                key: "hcsys",
                title: "空调焓差试验室",
                img: [
                    "/试验室/焓差试验室/芜湖美博低温防爆焓差.png",
                    "/试验室/焓差试验室/芜湖美博低温防爆焓差6.png",
                    // "/试验室/焓差试验室/上海海关焓差1.jpg",
                    // "/试验室/焓差试验室/上海海关焓差2.jpg",
                    "/试验室/焓差试验室/上海海关焓差3.jpg",
                    "/试验室/焓差试验室/上海海关焓差4.jpg",
                    "/试验室/焓差试验室/上海焓差.jpg",
                    "/试验室/焓差试验室/芜湖美博低温防爆焓差2.png",
                    "/试验室/焓差试验室/芜湖美博低温防爆焓差3.png",
                    "/试验室/焓差试验室/芜湖美博低温防爆焓差4.png",
                    "/试验室/焓差试验室/电气施工.jpg",
                ],
                text: "",
            },
            {
                key: "flsys",
                title: "风冷冷水(热泵)机组试验室",
                img: [
                    "/试验室/风冷试验室/风冷试验装置1.jpg",
                    "/试验室/风冷试验室/风冷试验装置2.jpg",
                    "/试验室/风冷试验室/风冷试验装置3.jpg",
                ],
                text: "",
            },
            {
                key: "slsys",
                title: "水冷冷水(热泵)机组试验室",
                img: [
                    "/试验室/水冷试验室/南京磁谷现场1.jpg",
                    "/试验室/水冷试验室/浙江美意现场1.jpg",
                    "/试验室/水冷试验室/浙江美意现场2.jpg",
                    "/试验室/水冷试验室/浙江美意现场3.jpg",
                    "/试验室/水冷试验室/浙江美意现场4.jpg",
                    "/试验室/水冷试验室/浙江现场.jpg",
                ],
                text: "",
            },
            {
                key: "lcqcktsys",
                title: "汽车空调试验室",
                img: [
                    "/试验室/列车汽车空调试验室/汽车空调试验室1.png",
                    "/试验室/列车汽车空调试验室/汽车空调试验室2.png",
                ],
                text: "",
            },
            {
                key: "sbfmsys",
                title: "水泵阀门试验室",
                img: [
                    "/试验室/水泵阀门试验室/阀门试验室1.png",
                    "/试验室/水泵阀门试验室/水泵试验室1.png",
                    "/试验室/水泵阀门试验室/水泵试验室2.png",
                ],
                text: "",
            },
            {
                key: "gyldsys",
                title: "工业冷冻主机及末端试验装置",
                img: [
                    "/试验室/工业冷冻试验室/烟台奥威工业冷冻项目.png",
                    "/试验室/工业冷冻试验室/烟台奥威工业冷冻项目2.png",
                    "/试验室/工业冷冻试验室/烟台奥威工业冷冻项目3.png",
                    "/试验室/工业冷冻试验室/烟台奥威工业冷冻项目4.png",
                ],
                text: "",
            },
            {
                key: "zxjcsys",
                title: "自动化在线检测系统",
                img: [
                    "/试验室/在线检测实验室/合肥科希曼机组在线检测1.png",
                    "/试验室/在线检测实验室/合肥科希曼机组在线检测2.png",
                    "/试验室/在线检测实验室/合肥科希曼机组在线检测3.png",
                    "/试验室/在线检测实验室/机组在线检测1.png",
                    "/试验室/在线检测实验室/机组在线检测2.png",
                ],
                text: "",
            },
        ],
    },
    csrj: {
        name: "测控软件",
        key: "csrj",
        children: [
            // {
            //     key: "csbg",
            //     title: "测试报告",
            //     img: [
            //         "/测试软件/测试报告/测试软件报告1.png",
            //         "/测试软件/测试报告/测试软件报告2-ahri.png",
            //     ],
            //     text: "",
            // },
            {
                key: "hccsrj",
                title: "焓差测试软件",
                img: [
                    "/测试软件/焓差测试软件/青岛三菱重工海尔60HP测试软件.png",
                    "/测试软件/焓差测试软件/青岛三菱重工海尔60HP测试软件2.png",
                    "/测试软件/焓差测试软件/芜湖美博低温防爆焓差试验室软件.png",
                ],
                text: "",
            },
            {
                key: "fpcsrj",
                title: "风盘测试软件",
                img: [
                    "/测试软件/风盘测试软件/风盘测试软件1.png",
                    "/测试软件/风盘测试软件/风盘测试软件2.png",
                ],
                text: "",
            },
            {
                key: "jzzxjcrj",
                title: "机组在线检测软件",
                img: [
                    "/测试软件/机组在线检测软件/合肥科希曼机组在线检测4.png",
                    "/测试软件/机组在线检测软件/特灵机组在线测试测试软件2.png",
                    "/测试软件/机组在线检测软件/特灵机组在线测试软件3.png",
                    "/测试软件/机组在线检测软件/特灵机组在线测试软件4.png",
                ],
                text: "",
            },
            {
                key: "lsjzcsrj",
                title: "冷水机组测试软件",
                img: [
                    "/测试软件/冷水机组测试软件/图片1.png",
                    "/测试软件/冷水机组测试软件/烟台奥威测试软件报告测试软件.png",
                    "/测试软件/冷水机组测试软件/烟台奥威制冷机组测试软件.png",
                    "/测试软件/冷水机组测试软件/烟台欧森纳中央空调测试软件.png",
                ],
                text: "",
            },
            {
                key: "qcktlcktcsrj",
                title: "汽车/列车空调测试软件",
                img: [
                    // "/测试软件/汽车空调列车空调测试软件/湖北美标汽车空调测试软件1.png",
                    "/测试软件/汽车空调列车空调测试软件/湖北美标汽车空调测试软件2.png",
                    "/测试软件/汽车空调列车空调测试软件/列车空调测试软件1.png",
                ],
                text: "",
            },
            {
                key: "sbfmcsrj",
                title: "水泵阀门测试软件",
                img: ["/测试软件/水泵阀门测试软件/水泵测试软件.png"],
                text: "",
            },
            {
                key: "ysjkyjcsrj",
                title: "压缩机/空压机测试软件",
                img: [
                    "/测试软件/压缩机空压机测试软件/空压机测试软件.png",
                    "/测试软件/压缩机空压机测试软件/空压机测试软件2.png",
                    "/测试软件/压缩机空压机测试软件/压缩机测试软件.png",
                ],
                text: "",
            },
            {
                key: "zdhcsrj",
                title: "自动化测试软件",
                img: [
                    "/测试软件/自动化测试软件/广州约克lab7自动化测试软件.png",
                    "/测试软件/自动化测试软件/广州约克lab7自动化测试软件2.png",
                    "/测试软件/自动化测试软件/特灵自动化测试软件.png",
                ],
                text: "",
            },
        ],
    },
    xxrj: {
        name: "选型软件",
        key: "xxrj",
        children: [
            {
                key: "pgxxrj",
                title: "盘管选型软件",
                img: [
                    "/选型软件/盘管选型软件/盘管选型软件1.png",
                    "/选型软件/盘管选型软件/盘管选型软件2.png",
                    "/选型软件/盘管选型软件/盘管选型软件3.png",
                ],
                text: "",
            },
            {
                key: "zjxxrj",
                title: "主机选型软件",
                img: [
                    "/选型软件/主机选型软件/欧科主机选型软件.png",
                    "/选型软件/主机选型软件/欧科主机选型软件2.png",
                    "/选型软件/主机选型软件/欧科主机选型软件3.png",
                    "/选型软件/主机选型软件/主机选型软件1.png",
                    "/选型软件/主机选型软件/主机选型软件2.png",
                ],
                text: "",
            },
            {
                key: "ahuxxrj",
                title: "AHU选型软件",
                img: [
                    "/选型软件/AHU选型软件/AHU选型软件1.png",
                    "/选型软件/AHU选型软件/AHU选型软件2.png",
                    "/选型软件/AHU选型软件/AHU选型软件3.png",
                    "/选型软件/AHU选型软件/AHU选型软件4.png",
                ],
                text: "",
            },
        ],
    },
    qtrj: {
        name: "其他软件",
        key: "qtrj",
        children: [
            {
                key: "bbzhglxt",
                title: "报表综合管理系统",
                img: [
                    "/其他软件/报表综合管理系统/空压机报表上传与分析1.png",
                    "/其他软件/报表综合管理系统/空压机报表上传与分析2.png",
                    "/其他软件/报表综合管理系统/空压机报表上传与分析3.png",
                    "/其他软件/报表综合管理系统/空压机报表上传与分析4.png",
                ],
                text: "",
            },
            {
                key: "mjldjkrj",
                title: "模具流道监控软件",
                img: [
                    "/其他软件/模具流道监控软件/模具流道监控1.png",
                    "/其他软件/模具流道监控软件/模具流道监控2.png",
                ],
                text: "",
            },
            {
                key: "mkhjfjkxt",
                title: "模块化机房监控系统",
                img: [
                    "/其他软件/模块化机房监控系统/模块化机房监控.png",
                    "/其他软件/模块化机房监控系统/模块化机房监控2.png",
                ],
                text: "",
            },
            {
                key: "wlglrj",
                title: "物料管理软件",
                img: [
                    "/其他软件/物料管理软件/物料管理系统1.png",
                    "/其他软件/物料管理软件/物料管理系统2.png",
                    "/其他软件/物料管理软件/物料管理系统5.png",
                ],
                text: "",
            },
        ],
    },
};

export default {
    productData,
};
