import Mock from "mockjs";

const homeData = {
    about: {
        title: "公司简介",
        text1: "合肥立梦软件技术有限公司成立于2015年，面向制冷空调及流体机械行业，提供专业的产品检测系统一揽子解决方案和产品设计选型软件系统。我们拥有卓越的系统、电气及软件工程师，专业技术过硬、项目经验丰富、团队非常稳定，为行业厂家服务超过十五年以上，是值得信赖的长期战略合作伙伴。",
        text2: "除此之外，我们一直与高校教授、领域专家、同行专业团队、国内外行业协会等保持沟通、合作，为客户提供一流的系统方案，解决客户痛点，实现共赢。",
        text3: "我们采用最先进的设计思路、开发平台、工艺流程等各种保障措施，确保“同样的硬件，更好的软件”，使得客户最终获得一种高度集成与优化的智能软硬件系统。",
        text4: "Let's Make Success!",
        img: "images/banner/about.jpg",
    },
    itemsProduct: [
        {
            key: "sys",
            img: "images/home/laboratory.png",
            name: "产品检测系统",
            text: "",
        },
        {
            key: "csrj",
            img: "images/home/testingSoftware.png",
            name: "测控软件",
            text: "",
        },
        {
            key: "xxrj",
            img: "images/home/SelectionSoftware.png",
            name: "选型软件",
            text: "",
        },
    ],
    itemsCase: [
        { key: 1, img: "images/case/case1.jpg", name: "成功案例1" },
        { key: 2, img: "images/case/case2.jpg", name: "成功案例2" },
        { key: 3, img: "images/case/case3.jpg", name: "成功案例3" },
        { key: 4, img: "images/case/case4.jpg", name: "成功案例4" },
        { key: 5, img: "images/case/case5.jpg", name: "成功案例5" },
        { key: 6, img: "images/case/case6.jpg", name: "成功案例6" },
        { key: 7, img: "images/case/case7.jpg", name: "成功案例7" },
        { key: 8, img: "images/case/case8.jpg", name: "成功案例8" },
        { key: 9, img: "images/case/case9.jpg", name: "成功案例9" },
        { key: 10, img: "images/case/case10.jpg", name: "成功案例10" },
    ],
    itemsHonor: [
        { key: 1, img: "images/honor/honor1.jpg", name: "荣誉资质1" },
        { key: 2, img: "images/honor/honor2.jpg", name: "荣誉资质2" },
        { key: 3, img: "images/honor/honor3.jpg", name: "荣誉资质3" },
        { key: 4, img: "images/honor/honor4.jpg", name: "荣誉资质4" },
        { key: 5, img: "images/honor/honor5.jpg", name: "荣誉资质5" },
        { key: 6, img: "images/honor/honor6.jpg", name: "荣誉资质6" },
        { key: 7, img: "images/honor/honor7.jpg", name: "荣誉资质7" },
        { key: 8, img: "images/honor/honor8.jpg", name: "荣誉资质8" },
        { key: 9, img: "images/honor/honor9.jpg", name: "荣誉资质9" },
        { key: 10, img: "images/honor/honor10.jpg", name: "荣誉资质10" },
    ],
    itemsCooperation: [
        { key: 1, img: "images/flink/logo3.jpg" },
        { key: 7, img: "images/flink/logo7.jpg" },
        { key: 23, img: "images/flink/logo23.jpg" },
        { key: 11, img: "images/flink/logo11.jpg" },
        { key: 13, img: "images/flink/logo13.jpg" },
        { key: 16, img: "images/flink/logo16.jpg" },
        { key: 14, img: "images/flink/logo14.jpg" },
        { key: 3, img: "images/flink/logo1.jpg" },
        { key: 4, img: "images/flink/logo4.jpg" },
        { key: 5, img: "images/flink/logo5.jpg" },
        { key: 6, img: "images/flink/logo6.jpg" },
        { key: 2, img: "images/flink/logo2.jpg" },
        { key: 8, img: "images/flink/logo8.jpg" },
        { key: 10, img: "images/flink/logo10.jpg" },
        { key: 9, img: "images/flink/logo9.jpg" },
        { key: 12, img: "images/flink/logo12.jpg" },
        { key: 15, img: "images/flink/logo15.jpg" },
        { key: 17, img: "images/flink/logo17.jpg" },
        { key: 18, img: "images/flink/logo18.jpg" },
        { key: 19, img: "images/flink/logo19.jpg" },
        { key: 20, img: "images/flink/logo20.jpg" },
        { key: 21, img: "images/flink/logo21.jpg" },
        { key: 22, img: "images/flink/logo22.jpg" },
        { key: 24, img: "images/flink/logo24.jpg" },
    ],
};

export default {
    homeData,
};
