<!--
 * @Author: 吴庆垚 wuqyhf@163.com
 * @Date: 2022-05-24 14:39:02
 * @LastEditors: 吴庆垚 wuqyhf@163.com
 * @LastEditTime: 2022-05-24 15:19:34
 * @FilePath: \VueTestSoftware\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
    <!-- <nav>
    <router-link to="/maintest">Home</router-link> |
  </nav> -->
    <router-view />
</template>

<style lang="scss">
@import "../src/assets/icons/iconfont.css";
html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    height: 100%;
}
</style>
