import Mock from "mockjs";

const contactData = {
    adress: "安徽省合肥市蜀山区井岗路CBC拓基广场B座1801",
    phone: "13515604873",
    qq: "3240647331",
    fax: "0551-63656991-802",
    postcode: "230031",
};

export default {
    contactData,
};
