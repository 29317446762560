/*
 * @Author: 吴庆垚 wuqyhf@163.com
 * @Date: 2022-05-27 11:56:20
 * @LastEditors: 吴庆垚 wuqyhf@163.com
 * @LastEditTime: 2022-05-31 08:37:53
 * @FilePath: \VueTestSoftware\src\mock\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Mock from "mockjs";
import Login from "./login";
import home from "./home";
import about from "./about";
import cases from "./case";
import honor from "./honor";
import product from "./product";
import contact from "./contact";

//登录界面
Mock.mock(new RegExp("/api/login"), "post", Login.login);

//网站首页
Mock.mock(new RegExp("/api/home/homedata"), "post", home.homeData);

//公司简介
Mock.mock(new RegExp("/api/about/aboutdata"), "post", about.aboutData);

//成功案例
Mock.mock(new RegExp("/api/case/casedata"), "post", cases.caseData);

//荣誉资质
Mock.mock(new RegExp("/api/honor/honordata"), "post", honor.honorData);

//产品中心
Mock.mock(new RegExp("/api/product/productdata"), "post", product.productData);

//产品中心
Mock.mock(new RegExp("/api/contact/contactdata"), "post", contact.contactData);
