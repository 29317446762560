import Mock from "mockjs";

const aboutData = {
    title: "关于我们",
    text1: "合肥立梦软件技术有限公司成立于2015年，面向制冷空调及流体机械行业，提供专业的产品检测系统一揽子解决方案和产品设计选型软件系统。我们拥有卓越的系统、电气及软件工程师，专业技术过硬、项目经验丰富、团队非常稳定，为行业厂家服务超过十五年以上，是值得信赖的长期战略合作伙伴。",
    text2: "除此之外，我们一直与高校教授、领域专家、同行专业团队、国内外行业协会等保持沟通、合作，为客户提供一流的系统方案，解决客户痛点，实现共赢。",
    text3: "我们采用最先进的设计思路、开发平台、工艺流程等各种保障措施，确保“同样的硬件，更好的软件”，使得客户最终获得一种高度集成与优化的智能软硬件系统。",
    text4: "Let's Make Success!",
    bannerimg: "images/banner/about.jpg",
};

export default {
    aboutData,
};
